<template>
  <div class="team">
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center mb-3 subtitle-4 black--text"> تسديدات الطالب : {{ $route.params.student_name }} </h1>
      <h3 class="text-center mb-3 subtitle-5 black--text"> الفاتورة: {{ $route.params.name }}</h3>
      <v-row>
        <v-col md="3" sm="3" cols="12" align-self="center">
          <v-btn tile color="primary" @click="addDialog.open = true"> اضافة عملية <v-icon right style="font-size: 15px">
              fa-plus </v-icon>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="3" sm="3" cols="12">
          <v-text-field v-model="table.search" label="البحث" append-icon="mdi-magnify" single-line hide-details>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table :loading="table.loading" loading-text="جاري التحميل ... الرجاء الانتظار" :headers="headers"
            :items="driversData" :search="table.search" :items-per-page="10" item-key="_id" class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
            }">
            <template slot="item._id" slot-scope="props"> {{ props.index + 1 }} </template>
            <template v-slot:item.type="{ item }">
              <span v-if="item.type === 'salary'">المبلغ</span>
              <span v-else-if="item.type === 'payment'">تسديد</span>
              <span v-else>خصم</span>
            </template>

            <template v-slot:item.amount="{ item }">
              {{ numberWithComma(item.amount) }}
            </template>
            <template v-slot:item.created_at="{ item }"> {{ item.createdAt }} </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="editItem(item)">
                    fa-edit </v-icon>
                </template>
                <span>تعديل</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FF8A80" v-bind="attrs" size="18" v-on="on" @click="deleteItem(item)"> fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row>
                <v-col cols="12">
                  <v-select v-model="payments.type" :items="paymentsType" dense label="اختيار نوع العملية" outlined
                    item-text="text" item-value="value"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="payments.amount" dense @keypress="isNumber($event)" label="المبلغ"
                    :rules="rules.amount" outlined></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-menu v-model="menuNextPayment" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="payments.next_payment" dense label="تاريخ الدفعة القادمة" outlined
                        clearable readonly v-bind="attrs" v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="payments.next_payment" @input="menuNextPayment = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea outlined rows="4" row-height="20" v-model="payments.desc" label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>

            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="addDialog.loading" :disabled="!addDialog.isFormValidAdd" @click="addData">
            اضافة </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Class dialog -->
    <!-- edit Class dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row no-gutters>
                <v-col cols="12" v-if="editedItem.type === 'payment' || editedItem.type === 'discount'">
                  <v-select v-model="editedItem.type" :items="paymentsType" dense label="اختيار نوع العملية" outlined
                    item-text="text" item-value="value"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="editedItem.amount" dense @keypress="isNumber($event)" label="المبلغ"
                    :rules="rules.amount" outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu v-model="menuNextPaymentEdit" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedItem.next_payment" dense label="تاريخ الدفعة القادمة" outlined
                        clearable readonly v-bind="attrs" v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.next_payment" @input="menuNextPaymentEdit = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined rows="3" row-height="20" v-model="editedItem.desc" label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>

            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="dialogEdit.loading" :disabled="!dialogEdit.isFormValid"
            @click="editItemConform"> تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Class dialog -->
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا الحساب ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from '@/api/api';
import numberWithComma from '@/constant/number';

export default {

  data() {
    return {

      menuNextPaymentEdit: false,

      menuNextPayment: false,

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false
      },

      payments: {
        name: null,
        amount: null,
        type: "payment",
        next_payment: null,
        study_year: null,
        desc: null
      },

      paymentsType: [
        { text: "تسديد", value: "payment" },
        { text: "خصم", value: "discount" },
      ],


      deleteItemLoading: false,

      dialogDelete: false,

      rules: {
        name: [value => !!value || 'الاسم مطلوب'],
        amount: [value => !!value || 'المبلغ مطلوب'],
      },

      editedItem: {},

      class_data: {
        selectedClass: null,
        class_school_leader: null,
      },

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      table: {
        loading: false,
        search: null,
      },

      dialogEdit: {
        open: false,
        isFormValid: false,
        loading: false,
      },

      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: '_id',
        },
        { text: 'المبلغ', sortable: false, value: 'amount' },
        { text: 'النوع', sortable: false, value: 'type' },
        { text: 'تاريخ الدفعة القادمة', sortable: false, value: 'next_payment' },
        { text: 'تاريخ الانشاء', sortable: false, value: 'createdAt' },
        { text: 'الملاحظة', sortable: false, value: 'desc' },
        { text: 'العمليات', value: 'actions', sortable: false },
      ],
      driversData: [],
      deleteItemLoading: false,
      dialogDelete: false,
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    async getData() {
      this.table.loading = true

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getStudentPayment({ isDeleted: false, study_year, invoice_id: this.$route.params.id })

      let payments = response.data.results.length >= 1 ? response.data.results[0].payments : []
      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.driversData = payments
      }
    },


    getDateAndTime(milisecondsDate) {
      let date = new Date(milisecondsDate);

      return date.toLocaleString("en-Us")
    },


    showBillsDetails() {

    },


    async addData() {
      this.addDialog.loading = true
      const study_year = JSON.parse(localStorage.getItem('study_year'))
      this.payments.study_year = study_year
      const response = await Api.addStudentPayment(this.payments, this.$route.params.id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.deleteItemLoading = true

      const response = await Api.removeStudentPayment(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    editItem(item) {
      this.editedItem = { ...item }
      this.dialogEdit.open = true
    },

    async editItemConform() {
      this.dialogEdit.loading = true

      const response = await Api.editStudentPayment({ amount: this.editedItem.amount, type: this.editedItem.type, desc: this.editedItem.desc, next_payment: this.editedItem.next_payment, id: this.editedItem._id })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false

        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },


    numberWithComma,

  },
}
</script>

<style>
.teacher_image_table {
  cursor: pointer;
}
</style>

<style scoped>
a {
  text-decoration: none;
}
</style>
